<template>
    <div class="bg-gradient-to-r from-gray-600 to-gray-800
            grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3
            text-gray-50 p-4
        ">
        <!-- Liens et réseaux sociaux -->
        <div class="w-full flex justify-between items-center order-1 lg:order-1">
            <social-links-vertical />
            <div class="w-full pl-4 border-l border-gray-200">
                <Link
                    :href="route(locale+'_about',{locale:locale})"
                    :class="['block px-4 py-2 text-sm text-gray-50']"
                >
                    {{ $t('footerBlock.about_us') }}
                </Link>
                <Link
                    :href="route(locale+'_support',{locale:locale})"
                    :class="['block px-4 py-2 text-sm text-gray-50']"
                >
                    {{ $t('footerBlock.contact_us') }}
                </Link>
                <Link
                    :href="route(locale+'_terms.and.conditions',{locale:locale})"
                    :class="['block px-4 py-2 text-sm text-gray-50']"
                >
                    {{ $t('navBar.termsAndConditions') }}
                </Link>
                <Link
                    :href="route(locale+'_privacy.policy',{locale:locale})"
                    :class="['block px-4 py-2 text-sm text-gray-50']"
                >
                    {{ $t('privacyPolicy.title') }}
                </Link>
                <Link
                    :href="route(locale+'_tandcs',{locale:locale})"
                    :class="['block px-4 py-2 text-sm text-gray-50']"
                >
                    {{ $t('navBar.tandcs') }}
                </Link>
            </div>
        </div>
        <!-- Logo et description -->
        <div class="w-full p-4 text-center font-bold order-3 lg:order-2">
            <ImgLoading
                :AttributeImg="{
                source:'/images/logo/logo.webp',
                altText:$t('logo.alt'),
                titleText:$t('logo.title'),
                className:'w-auto mx-auto',
                srcset: `/images/logo/logo-sm.webp 480w,
                        /images/logo/logo-md.webp 800w,
                        /images/logo/logo-lg.webp 1200w,
                        /images/logo/logo-xl.webp 2400w,
                        /images/logo/logo.webp 3600w`,
                sizes: `(max-width: 480px) 100vw,
                    (max-width: 800px) 50vw,
                    (max-width: 1200px) 33vw,
                    (max-width: 2400px) 25vw,
                    (max-width: 3200px) 20vw,
                    16vw`,
                width: '3600',  // Dimensions de l'image la plus grande
                height: '3600'
            }"
            />
            <div class="lg:text-2xl">
                {{$t('footerBlock.slogan')}}
            </div>
            <p class="w-full">
                {{$t('footerBlock.slogan2')}}
            </p>
        </div>
        <!-- newsletters -->
        <div class="w-full flex items-center order-2 lg:order-3">
            <news-letter-footer />
        </div>
    </div>

</template>
<script setup>
    import ImgLoading from "@/Components/ImgLoading.vue";
    import {Link} from "@inertiajs/vue3";
    import { useI18n } from 'vue-i18n';
    import NewsLetterFooter from "@/Components/NewsLetterFooter.vue";
    import SocialLinksVertical from "@/Components/SocialLinksVertical.vue";
    const { locale } = useI18n();
</script>
