// resources/js/utils/cache.js (ou où tu veux le placer)

export function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // 'item' est un objet contenant les données et un timestamp d'expiration
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // Si l'item n'existe pas, retourne null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Vérifie si l'item est expiré
    if (now.getTime() > item.expiry) {
        // Supprime l'item du localStorage
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}
