<template>
    <div class="w-full p-6 rounded-lg shadow-lg space-y-4 text-gray-100">
        <h2 class="text-xl font-bold mb-4">{{ $t('newsletter.title') }}</h2>
        <div v-if="emailOk === ''" class="flex justify-between gap-8">
            <div class="w-full">
                <input-text :inputValue="email" :label="$t('welcome.emailLabel')" :inputName="'email'" class="w-full" @returnValue="handleUpDateFiled" ref="getInputValue" />
            </div>
            <div
                class="w-36"
            >
                <button :class="[(isEmailValid)?'opacity-85':'opacity-65',
                            'p-4 rounded-2xl bg-gradient-to-tl from-lime-500 via-pink-600 to-fuchsia-600 ']"
                        @click="openModal" :disabled="!isEmailValid">
                    {{ $t('welcome.emailButton') }}
                </button>
            </div>
        </div>
        <div v-else>
            {{ emailOk }}
        </div>
        <div v-if="isModalOpen"
             class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
        >
            <div v-if="!emailKo"
                 class="p-4 rounded-lg shadow-xl max-w-lg w-full h-96 overflow-auto scroll-auto  bg-white text-gray-700"
            >
                <h2 class="text-xl font-bold mb-4">{{ $t('welcome.modalTitle') }}</h2>
                <p>{{ $t('welcome.modalDescription') }}</p>

                <h3 class="text-lg font-bold mt-4">{{ $t('welcome.modalRGPDTitle') }}</h3>
                <p class="mt-2">{{ $t('welcome.modalRGPDText') }}</p>
                <ul class="list-disc list-inside ml-4 mt-2">
                    <li>{{ $t('welcome.modalRGPDRight1') }}</li>
                    <li>{{ $t('welcome.modalRGPDRight2') }}</li>
                    <li>{{ $t('welcome.modalRGPDRight3') }}</li>
                    <li>{{ $t('welcome.modalRGPDRight4') }}</li>
                    <li>{{ $t('welcome.modalRGPDRight5') }}</li>
                    <li>{{ $t('welcome.modalRGPDRight6') }}</li>
                </ul>
                <p class="mt-2">{{ $t('welcome.modalContactText') }}</p>
                <p class="mt-4">{{ $t('welcome.modalThanks') }}</p>
                <div class="mt-4 text-right flex justify-end gap-8">
                    <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" @click="closeModal">{{ $t('welcome.modalClose') }}</button>
                    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="addEmail">{{ $t('welcome.modalAccept') }}</button>
                </div>
            </div>
            <div v-else class="bg-white text-gray-700 rounded-lg overflow-hidden shadow-xl max-w-lg w-full p-6">
                <div class="my-3">{{ $t('welcome.modalEmailExists') }}</div>
                <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" @click="closeModal">{{ $t('welcome.modalClose') }}</button>
            </div>
        </div>
    </div>

</template>
<script setup>
import InputText from "@/Components/Form/InputText.vue";
import {computed, ref} from "vue";
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();


const email = ref('');
const emailOk = ref('');
const emailKo = ref(false);
const isModalOpen = ref(false);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const isEmailValid = computed(() => emailRegex.test(email.value));
async function addEmail() {
    try {
        const {data} = await axios.post(route('rest.interet.add.email',{locale:locale.value}), {email: email.value});
        emailOk.value = data.message;
        emailKo.value = false;
        closeModal();
    } catch (e) {
        if (e.response && e.response.status === 422 && e.response.data.errors) {
            const errors = e.response.data.errors;
            if (errors.email && errors.email.includes("The email has already been taken.")) {
                emailKo.value = true;
            }
        }
        console.error(e);
    }
}
function openModal() {
    if (email.value !== '') {
        isModalOpen.value = true;
    }
}
function closeModal() {
    emailKo.value = false;
    email.value = '';
    isModalOpen.value = false;
}
function handleUpDateFiled(e) {
    email.value = e.value;
}

</script>